import { mapHelper } from "@/utils/common.js";

//页面类型
const pagesType = [
  {
    value: 1,
    label: "小程序原生页面",
  },
  {
    value: 2,
    label: "h5页面",
  },
  {
    value: 3,
    label: "第三方h5页面",
  },
  {
    value: 4,
    label: "第三方小程序",
  },
];

const { map: pagesTypeMap, setOps: pagesTypeOps } = mapHelper.setMap(pagesType);

//入口类型
const inType = [
  {
    value: 1,
    label: "小程序",
  },
];
const { map: inTypeMap, setOps: inTypeOps } = mapHelper.setMap(inType);
//是否首页展示
const isShowHomeType = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 0,
    label: "否",
  },
];
const { map: isShowHomeTypeMap, setOps: isShowHomeTypeOps } =
  mapHelper.setMap(isShowHomeType);
export {
  pagesType,
  pagesTypeMap,
  inType,
  inTypeMap,
  isShowHomeTypeMap,
  isShowHomeType,
};
